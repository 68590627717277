<template>
  <div>
    <b-card class="full-height mb-0">
      <div>
        <div>
          <div class="d-flex align-items-center mb-1">
            <feather-icon
              v-if="reply.accepted"
              icon="CheckCircleIcon"
              size="18"
              class="mr-50 text-success"
            />
            <p class="font-small-3 font-weight-bold text-muted mb-0">
              №{{ reply.id }}
            </p>
            <div class="flex-fill" />
            <span class="text-muted font-small-3">{{ reply.created | dateTime }}</span>
          </div>
          <p class="mb-0 font-medium-1 font-weight-bold text-body">
            {{ teacher.full_name }}
          </p>
          <p class="mb-0 font-small-3 text-muted">
            +{{ teacher.phone }}
          </p>
        </div>
        <div />
      </div>

      <b-alert
        show
        class="mt-1"
        :variant="hasPrice ? 'info' : 'warning'"
      >
        <div class="alert-body">
          <span v-if="hasPrice">Стоимость занятия: <b>{{ reply.lesson_price }}</b> руб.</span>
          <span v-else>Стоимость занятия не определена</span>
        </div>
      </b-alert>

      <div v-if="reply.comment">
        <p class="mb-0 text-muted font-small-3">
          Комментарий
        </p>
        <p class="text-body">
          {{ reply.comment }}
        </p>
      </div>

      <div class="thin-divider my-1" />
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
          <b-button
            v-if="canAccept"
            variant="flat-success"
            size="sm"
            :disabled="!hasPrice"
            @click="acceptReply"
          >
            <b-spinner
              v-if="accepting"
              class="mr-50"
              small
            />
            <feather-icon
              v-else
              icon="CheckIcon"
              class="mr-50"
            />
            <span v-if="accepting">В процессе...</span>
            <span v-else>Выбрать</span>
          </b-button>
        </div>
        <div class="flex-fill" />
        <lead-reply-chat-history
          v-if="reply.chat"
          :reply="reply"
        >
          <template #activator="{show}">
            <b-button
              variant="flat-primary"
              size="sm"
              @click="() => { resetUnread(); show() }"
            >
              <div class="d-flex align-items-center">
                <b-badge
                  pill
                  :variant="unreadMessages ? 'primary' : 'light-primary'"
                  class="mr-50 font-small-2"
                >
                  <feather-icon icon="MessageSquareIcon" />
                  <span v-if="unreadMessages"> {{ unreadMessages }}</span>
                </b-badge>
                <span>Чат</span>
              </div>
            </b-button>
          </template>
        </lead-reply-chat-history>
        <b-button
          v-else
          variant="flat-primary"
          size="sm"
          @click="createChat"
        >
          <b-spinner
            v-if="creatingChat"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="PlusIcon"
            class="mr-50"
          />
          <span v-if="creatingChat">Создание...</span>
          <span v-else>Создать чат</span>
        </b-button>
      </div>
    </b-card>

    <confirm-modal
      ref="acceptConfirm"
      :yes="'Принять'"
      color="success"
      :text="`Вы уверены, что хотите выбрать данного репетитора?`"
    />
  </div>
</template>

<script>
import {
  BCard, BButton, BBadge, BSpinner, BAlert,
} from 'bootstrap-vue'
import LeadReplyChatHistory from '../LeadReplyChatHistory.vue'
import ConfirmModal from '../../../page-elements/modals/ConfirmModal.vue'

export default {
  name: 'LeadReplyCard',
  components: {
    ConfirmModal,
    LeadReplyChatHistory,
    BCard,
    BButton,
    BBadge,
    BSpinner,
    BAlert,
  },
  props: {
    reply: {
      type: Object,
      required: true,
    },
    canAccept: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    creatingChat: false,
    accepting: false,
  }),
  computed: {
    hasPrice() {
      return !!this.reply.lesson_price
    },
    teacher() {
      return this.reply.user
    },
    unreadMessages() {
      return this.reply.chat && this.reply.chat.unread_number
    },
  },
  methods: {
    acceptReply() {
      this.$refs.acceptConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.accepting = true
            await this.$chatsHttp.post(`/lead-replies/${this.reply.id}/accept`).then(() => {
              this.$emit('onAccept')
            })
            this.accepting = false
          }
        })
    },
    resetUnread() {
      if (this.unreadMessages) this.reply.chat.unread_number = 0
    },
    async createChat() {
      this.creatingChat = true
      this.reply.chat = await this.$chatsHttp.post(`/lead-replies/${this.reply.id}/create-chat`)
      this.creatingChat = false
    },
  },
}
</script>

<style scoped>

</style>
