<template>
  <div>
    <lead-info-card
      :lead="lead"
      :publishing="publishing"
    />

    <template v-if="loadingReply">
      <div class="d-flex align-items-center">
        <b-spinner
          class="mr-50"
          small
        />
        <p class="mb-0">
          Загрузка отклика...
        </p>
      </div>
    </template>
    <template v-else-if="reply">
      <lead-reply-card :reply="reply" />
    </template>
    <template v-else>
      <p>Вы не откликались на заявку</p>
      <reply-to-lead-modal
        :lead="lead"
        @onReply="getReply"
      >
        <template #activator="{show}">
          <b-button
            variant="primary"
            @click="show"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            Откликнуться
          </b-button>
        </template>
      </reply-to-lead-modal>
    </template>
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import LeadInfoCard from '../../components/leads/lead_page/teacher/LeadInfoCard.vue'
import { LEAD_STATUS_DONE } from '../../shared/constants'
import ReplyToLeadModal from '../../components/leads/lead_page/teacher/ReplyToLeadModal.vue'
import LeadReplyCard from '../../components/leads/lead_page/teacher/LeadReplyCard.vue'

export default {
  name: 'LeadPage',
  components: {
    LeadReplyCard, ReplyToLeadModal, LeadInfoCard, BButton, BSpinner,
  },
  data: () => ({
    lead: null,
    reply: null,
    loading: false,
    loadingReply: false,
    publishing: false,
  }),
  computed: {
    leadId() {
      return this.$route.params.lead_id
    },
    isDone() {
      return this.lead?.status === LEAD_STATUS_DONE
    },
  },
  async created() {
    this.$eventBus.$emit('page-title', 'Загрузка заявки...')
    this.getLead()
    this.getReply()
  },
  methods: {
    async getLead() {
      this.loading = true
      this.lead = await this.$chatsHttp.get(`/leads/${this.leadId}`)
      this.$eventBus.$emit('page-title', `Заявка №${this.lead.displayed_id}`)
      this.loading = false
    },
    async getReply() {
      this.loadingReply = true
      await this.$chatsHttp.get(`/leads/${this.leadId}/reply`).then(response => {
        this.reply = response
      }).finally(() => {
        this.loadingReply = false
      })
    },
  },
}
</script>

<style scoped>

</style>
