import { render, staticRenderFns } from "./LeadReplyChatHistory.vue?vue&type=template&id=ac749120&scoped=true&"
import script from "./LeadReplyChatHistory.vue?vue&type=script&lang=js&"
export * from "./LeadReplyChatHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac749120",
  null
  
)

export default component.exports