<template>
  <div class="chat-application">
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-card
      v-if="fullscreen"
      class="card-fullscreen chat-app-window"
      no-body
    >
      <div class="container">
        <div class="d-flex align-items-center p-1">
          <h3 class="mb-0">
            Отклик №{{ reply.id }}
          </h3>
          <b-button
            v-if="reply.chat"
            :to="{ name: 'cabinet.admin.chat', query: { chat_id: reply.chat.id } }"
            class="ml-1"
            target="_blank"
            variant="outline-primary"
          >
            Открыть отдельно
            <feather-icon
              icon="ExternalLinkIcon"
              class="ml-50"
            />
          </b-button>
          <div class="flex-fill" />
          <b-button
            class="btn-icon"
            variant="flat-secondary"
            @click="close"
          >
            <feather-icon
              icon="XIcon"
              size="30"
            />
          </b-button>
        </div>
        <chat-area
          :selected-chat="chat"
          no-header
          full-height
          centered
          @chatClosed="chat = null"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ChatArea from '../../chat/ChatArea.vue'

export default {
  name: 'LeadReplyChatHistory',
  components: {
    ChatArea,
    BCard,
    BButton,
  },
  props: {
    reply: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    fullscreen: false,
    messages: [],
    initiallyLoaded: false,
    search: '',
    query: {
      limit: 16,
      search: '',
    },
    chatInputMessage: '',
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    chat() {
      return this.reply.chat
    },
  },
  methods: {
    show() {
      this.messages = []
      this.fullscreen = true
    },
    close() {
      this.fullscreen = false
    },
  },
}
</script>

<style scoped>

</style>
