<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      title="Отклик на заявку"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template #default="{ hide }">
        <!-- form -->
        <b-form @submit.prevent="sendReply">

          <div class="p-2">
            <!-- name -->
            <b-form-group
              label-for="message"
              label="Предложите цену"
            >
              <b-form-input
                id="message"
                v-model.trim="form.price"
                name="name"
                placeholder="Введите цену"
                autofocus
                required
                type="number"
              />
            </b-form-group>

            <!-- name -->
            <b-form-group
              label-for="text"
              label="Комментарий (необязательно)"
            >
              <b-form-textarea
                id="url"
                v-model.trim="form.comment"
                name="name"
                rows="3"
                placeholder="Введите текст комментария"
                autofocus
              />
            </b-form-group>
          </div>
          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
              :disabled="!form.price"
            >
              Откликнуться
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="hide"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormTextarea, BModal,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { toast } from '../../../../helpers'

export default {
  name: 'ReplyToLeadModal',
  components: {
    BModal,
    BButton,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      price: null,
      comment: null,
    },
    display: false,
    sending: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  watch: {
    display() {
      this.form = {
        price: null,
        comment: null,
      }
    },
  },
  methods: {
    async sendReply() {
      const { price, comment } = this.form

      if (!price.trim() || price < 1) {
        toast({ title: 'Введите корректную цену', type: 'warning' })
        return
      }

      this.sending = true
      await this.$chatsHttp.post(`/leads/${this.lead.id}/reply`, { price, comment })
        .then(() => {
          toast({ title: 'Отклик отправлен', type: 'success' })
          this.$emit('onReply')
          this.display = false
        }).catch(err => {
          toast({ title: 'Ошибка отправки', text: err?.data?.message, type: 'danger' })
          this.display = false
        })
      this.sending = false
    },
    show() {
      this.display = true
    },
  },
}
</script>

<style scoped>

</style>
