<template>
  <div>
    <b-card>
      <template v-if="lead">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="8"
          >
            <div class="px-1 pb-1 pt-0 border-right full-height">
              <b-row>
                <b-col>
                  <div class="mb-50">
                    <span :class="`text-${statusProps.color}`">
                      <feather-icon
                        :icon="statusProps.icon"
                        class="mr-50"
                      />
                      {{ statusProps.label }}
                    </span>
                    <span class="text-muted"> – cоздана {{ lead.created | dateTime }}</span>
                  </div>

                  <p
                    v-if="lead.subject"
                    class="font-weight-bold font-medium-5"
                  >
                    {{ lead.subject.name }}, {{ lead.grade.name }}
                  </p>
                  <p class="font-weight-bold font-medium-5 mb-50">
                    {{ lead.study_purpose }}
                  </p>
                  <p class="text-muted mb-50">
                    {{ lead.study_type }}
                  </p>

                  <div v-if="lead.comment">
                    <p class="mb-0 text-muted font-small-3">
                      Комментарий
                    </p>
                    <p class="text-body">
                      {{ lead.comment }}
                    </p>
                  </div>
                </b-col>

              </b-row>

              <b-row>

                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                  class="mt-1"
                >
                  <p class="text-muted font-small-3 mb-0">
                    Желаемое время занятий:
                  </p>
                  <p
                    class="mb-0"
                  >
                    {{ lead.preferred_hours || 'Любое' }}
                  </p>
                </b-col>
                <!--              <b-col-->
                <!--                v-if="lead.preferred_days.length"-->
                <!--                cols="12"-->
                <!--                md="6"-->
                <!--                lg="4"-->
                <!--                class="mt-1"-->
                <!--              >-->
                <!--                <p class="text-muted font-small-3 mb-0">-->
                <!--                  Желаемые дни занятий:-->
                <!--                </p>-->
                <!--                <p class="first-letter-capital font-weight-bold mb-50">-->
                <!--                  {{ lead.preferred_days.join(', ') }}-->
                <!--                </p>-->
                <!--              </b-col>-->
                <!--              <b-col-->
                <!--                v-if="lead.preferred_hours.length"-->
                <!--                cols="12"-->
                <!--                md="6"-->
                <!--                lg="4"-->
                <!--                class="mt-1"-->
                <!--              >-->
                <!--                <p class="text-muted font-small-3 mb-0">-->
                <!--                  Желаемые часы занятий:-->
                <!--                </p>-->
                <!--                <p class="first-letter-capital font-weight-bold mb-50">-->
                <!--                  {{ lead.preferred_hours.join(', ') }}-->
                <!--                </p>-->
                <!--              </b-col>-->
                <b-col
                  v-if="lead.rates.length"
                  cols="12"
                  md="6"
                  lg="4"
                  class="mt-1"
                >
                  <p class="text-muted font-small-3 mb-0">
                    Желаемая стоимость занятия:
                  </p>
                  <p class="first-letter-capital font-weight-bold mb-50">
                    {{ lead.rates.map(rate => rate.name).join(', ') }}
                  </p>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <div class="p-1">
              <div>
                <p class="text-muted font-small-3 mb-0">
                  Родитель:
                </p>
                <p class="first-letter-capital font-weight-bold mb-50">
                  {{ lead.parent_name }}
                </p>
              </div>
              <div>
                <p class="text-muted font-small-3 mb-0">
                  Ребенок:
                </p>
                <p class="first-letter-capital font-weight-bold mb-50">
                  {{ lead.child_name }} - {{ lead.grade ? lead.grade.name : 'Класс не указан' }}
                </p>
              </div>
              <div>
                <p class="text-muted font-small-3 mb-50">
                  Контактная информация:
                </p>
                <div class="d-flex align-items-center">
                  <div>
                    <a
                      :href="`tel:${lead.phone}`"
                      class="display-block font-weight-bold"
                    >
                      <feather-icon
                        icon="PhoneIcon"
                        class="mr-50"
                      />
                      <span>+{{ lead.phone }}</span>
                    </a>
                  </div>
                  <div v-if="lead.call_back">
                    <b-badge
                      variant="light-warning"
                      class="ml-1"
                    >
                      <feather-icon
                        icon="PhoneCallIcon"
                        class="mr-50"
                      />
                      Требуется звонок
                    </b-badge>
                  </div>
                </div>

              </div>
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="8"
          >
            <div class="p-1 border-right full-height">
              <skeletor
                width="50%"
                height="30px"
                class="mb-1"
              />
              <div class="d-flex align-items-center">
                <skeletor
                  width="200px"
                  height="20px"
                  class="mr-1"
                />
                <skeletor
                  width="200px"
                  height="20px"
                />
              </div>

              <b-row class="mt-1">
                <b-col
                  v-for="skel in 3"
                  :key="`skel-${skel}`"
                  cols="12"
                  md="6"
                  lg="4"
                  class="mt-1"
                >
                  <skeletor
                    width="40%"
                    height="14px"
                    class="mb-1"
                  />
                  <skeletor
                    width="65%"
                    height="22px"
                  />
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <div class="p-1">
              <div
                v-for="skel in 3"
                :key="`info-skel-${skel}`"
              >
                <skeletor
                  width="30%"
                  height="13px"
                  class="mb-50"
                />
                <skeletor
                  width="65%"
                  height="20px"
                  class="mb-1"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BBadge,
} from 'bootstrap-vue'
import { leadStatusProps } from '../../../../helpers'

export default {
  name: 'LeadInfoCard',
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
  },
  props: {
    lead: {
      type: Object,
      default: null,
    },
    publishing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusProps() {
      return leadStatusProps(this.lead.status)
    },
  },
  methods: {
    onHide() {
      this.$refs.hideConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$emit('onHide')
          }
        })
    },
    onDelete() {
      this.$refs.hideConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$emit('onDelete')
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
